contactenos{
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom:0;
    opacity: 0;
    visibility: hidden;
    z-index: 9999;
    @include ease-transition;
    background-color: #000;
    &.mostrarcontactenos{
        visibility: visible;
        opacity: 1
    }


    .black-screen{
        width: 100%;
        height: 100%;
        background-color: #000;
        z-index: 9999;
    }
    .close-btn{
        color:white;
        position: absolute;
    top:0;
    left:0;
    }
}