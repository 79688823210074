nosotros-info {
  .background {
    min-height: 650px;
    // height: 100vh;
    background-image: url("/assets/images/nosotros2-12.jpg");
    background-position: center bottom;
    background-size: 30%;
    background-repeat: no-repeat;
    @media (max-width:$btMD){
      background-size: 50%;
      background-image: unset;
    }
    .titulo-info {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &>span{
        font-family: montserrat_semi_bold;
        font-size: 200%;
        text-align: center;
        letter-spacing: 0.2rem;
        position: relative;
        padding: 10px;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 45px;
          height: 3px;
          background-color: #005cb8;
        }
  
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 45px;
          background-color: #005cb8;
        }
      }
      @media (max-width:$btMD){
        width: 70%;
      }
    
    }

    .contenedor {
      // margin-top:60px;
      position: relative;
      height: 600px;
      width: 100%;
      @media (max-width:$btMD){
      height: auto;
      margin-bottom:250px;
      }
      .item-elemento {
        margin:20px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // margin: auto;
        width: 22%;
        position: absolute;
        @media (max-width:$btMD){
          position:relative;
          width: 90%;
          margin-bottom: 10px;
        }
        &.primer{
          top:0;
          left:15%;
          @media (max-width:$btMD){
            top:0;
            left:0;
          }
        }
        &.segundo{
          top:0;
          right: 15%;
          @media (max-width:$btMD){
            top:0;
            left:0;
          }
        }
        &.tercero{
          // top:80%;
          bottom:0;
          left:5%;
          @media (max-width:$btMD){
            top:0;
            left:0;
          }
        }
        &.cuarto{
          // top:80%;
          bottom:0;
          right: 5%;
          @media (max-width:$btMD){
            top:0;
            left:0;
          }
        }
        .icono {
          @include minmaxwh(60px);
        }

        .titulo {
          &>span{
            text-align: center;
            letter-spacing: 0.2rem;
            font-family: montserrat_extra_bold;
          }
          @media (max-width:$btMD){
            margin: 5px 0;
          }
        }

        .texto {
          &>span{
            text-align: center;
            font-size:110%;
            color:#555;
            font-family: titillium_regular;
          }
          @media (max-width:$btMD){
            width: 80%;
          }
        }
      }
    }
  }
}