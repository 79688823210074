footer-section {
  position: relative;
  .footer-section {
    background-image: url('/assets/images/fondo-footer-nosotros.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 115vh;
    min-height: 760px;
    display: flex;
    justify-content: center;
    align-items: center; // padding: 20% 14% 2% 14%;
    position: relative;
    @media (max-width: $btLG) {
      flex-direction: column;
      height: auto;
      background-image: none;
      background-color: #021120;
    }
    .footer-contenedor {
      display: flex;
      justify-content: center;
      width: 75%;
      @media (max-width: $btLG) {
        flex-direction: column;
        height: auto;
        width: 90%;
        padding-bottom: 200px;
      }
      .contacto {
        height: 100%;
        width: 35%;
        color: white;
        font-family: montserrat_bold;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: $btLG) {
          width: 100%;
        }
        .titulo {
          text-transform: uppercase;
          letter-spacing: 0.2rem;
          margin-bottom: 7%;
          @media (max-width: $btLG) {
            text-align: center;
            margin-top: 50px;
          }
        }
        .ul {
          display: grid;
          grid-template-columns: 13% 1fr;
          grid-row-gap: 1px;
          width: 90%;
          .li-bul {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            i {
              margin-top: 5px;
              @include minmaxwh(18px);
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid white;
              border-radius: 50%;
              font-size: 50%;
            }
          }
          .li {
            align-items: center;
            font-family: titillium_light;
            padding-bottom: 7px;
            font-size: 103%;
            a {
              text-decoration: none;
              color: #bbb;
              @include ease-transition;
              &:hover {
                color: #fff;
              }
            }
          }
        }
        ul {
          font-family: titillium_light;
          li {
            font-size: 110%;
            line-height: 1.8rem;
            margin-bottom: 0.5rem;
            i {
              margin-top: 0.2rem;
              font-size: 75%; // @include minmaxwh(40px);
              // &::before{
              //   border:1px white solid;
              //   border-radius: 50%;
              // }
            }
          }
        }
        img {
          height: 90px;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
      .navegacion {
        width: 31%;
        color: white;
        font-family: titillium_light;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 20px;
        @media (max-width: $btLG) {
          width: 100%;
          margin: 0;
        }
        .tit-nav {
          font-family: montserrat_bold;
          text-transform: uppercase;
          letter-spacing: 0.2rem;
          font-size: 97%;
          @media (max-width: $btLG) {
            display: none;
          }
        }
        ul {
          @media (max-width: $btLG) {
            width: 0;
            height: 0;
            overflow: hidden;
          }
          margin-top: 20px;
          margin-bottom: auto;
          padding-inline-start: 20px;
          li {
            font-size: 110%;
            list-style-type: square;
            margin-bottom: 5px;
            position: relative;
            a,
            span {
              letter-spacing: 0.2rem;
              &:visited,
              &:link {
                color: white;
                text-decoration: none;
                &.active,
                &:hover {
                  font-family: titillium_bold;
                }
              }
            }
            ul.dropdownULf {
              display: flex;
              flex-wrap: wrap;
              padding: 0;
              margin: 0.4rem 0 0.6rem 0;
              margin-left: 0.6rem;
              li.dropdownLIf {
                width: 50%;
                @media (max-width: $btXL) {
                  width: 100%;
                  margin-bottom: 0;
                }
                a {
                  font-size: 50%;
                }
                &:hover {
                  a {
                    // &::after {
                    //   content: "";
                    //   position: absolute;
                    //   left: 13px;
                    //   right: 0;
                    //   bottom: 0;
                    //   width: 50%;
                    //   height: 2px;
                    //   background: #fff;
                    //   transform: translateX(-15px);
                    // }
                  }
                }
              }
            }
          }
        }
        form.newsletter {
          position: relative;
          @media (max-width: $btLG) {
            width: 100%;
            overflow-x: hidden;
          }
          label {
            font-family: titillium_bold;
            letter-spacing: 0.2rem;
          }
          input {
            all: unset;
            background-color: #fff;
            width: 62%;
            padding: 13px;
            color: #333;
            @media (max-width: $btXL) {
              width: 80%;
            }
            @media (max-width: $btLG) {
              width: 100%;
            }
            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #444;
              font-size: 70%;
            }
            &::-moz-placeholder {
              /* Firefox 19+ */
              color: #444;
              font-size: 70%;
            }
            &:-ms-input-placeholder {
              /* IE 10+ */
              color: #444;
              font-size: 70%;
            }
            &:-moz-placeholder {
              /* Firefox 18- */
              color: #444;
              font-size: 70%;
            }
          }
          button.newsbtn {
            font-family: titillium_bold;
            font-size: 75%;
            border: none;
            background: #005cb8;
            color: white;
            text-transform: uppercase;
            position: absolute;
            cursor: pointer;
            height: 50px;
            width: 30%;
            right: 0;
            bottom: 0;
            &:disabled {
              filter: brightness(50%);
              cursor: not-allowed;
            }
            &:after {
              position: absolute;
              content: '';
              height: 0px;
              width: 0px;
              left: -30px;
              bottom: 0px;
              border-left: 31px solid transparent;
              border-top: 50px solid #005cb8;
            }
          }
        }
      }
      .comuniquese {
        height: 100%;
        color: white;
        font-family: montserrat_bold;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        width: 31%;
        @media (max-width: $btLG) {
          width: 100%;
        }
        .titulo {
          @media (max-width: $btLG) {
            text-align: center;
            margin-top: 40px;
            margin-bottom: 20px;
          }
        }
        form {
          font-family: titillium_light;
          display: flex;
          flex-direction: column;
          position: relative;
          margin-top: auto;
          input {
            all: unset;
            background-color: #fff;
            margin: 5px 0;
            padding: 7px 14px;
            font-size: 110%;
            color: #333;
            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: #333;
              font-size: 70%;
            }
            &::-moz-placeholder {
              /* Firefox 19+ */
              color: #333;
              font-size: 70%;
            }
            &:-ms-input-placeholder {
              /* IE 10+ */
              color: #333;
              font-size: 70%;
            }
            &:-moz-placeholder {
              /* Firefox 18- */
              color: #333;
              font-size: 70%;
            }
          }
          .textarea {
            margin-top: 5px;
            padding: 10px 0 50px 14px;
            background-color: #fff;
            textarea {
              all: unset;
              background-color: #fff;
              font-size: 110%;
              height: 97px;
              resize: none;
              color: #333;
              width: 100%;
              width: -moz-available;
              &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #333;
                font-size: 70%;
              }
              &::-moz-placeholder {
                /* Firefox 19+ */
                color: #333;
                font-size: 70%;
              }
              &:-ms-input-placeholder {
                /* IE 10+ */
                color: #333;
                font-size: 70%;
              }
              &:-moz-placeholder {
                /* Firefox 18- */
                color: #333;
                font-size: 70%;
              }
            }
          }
          .formsbmt {
            border: none;
            background: #005cb8;
            color: white;
            text-transform: uppercase;
            position: absolute;
            height: 50px;
            width: 130px;
            right: 0;
            bottom: 0;
            font-family: titillium_bold;
            font-size: 75%;
            cursor: pointer;
            &:disabled {
              filter: brightness(50%);
              cursor: not-allowed;
            }
            &:after {
              position: absolute;
              content: '';
              height: 0px;
              width: 0px;
              left: -20px;
              bottom: 0px;
              border-left: 21px solid transparent;
              border-top: 50px solid #005cb8;
            }
          }
        }
      }
    }
  }
  .social {
    background: transparent;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 30vw;
    @media (max-width: $btLG) {
      left: 0;
      width: 100%;
    }
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @media (max-width: $btLG) {
        margin: auto;
        width: 100%;
      }
      li {
        list-style: none;
        position: relative;
        left: 100px;
        @media (max-width: $btLG) {
          left: 0;
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 50px;
          max-width: 50px;
          min-height: 50px;
          max-height: 50px;
          margin: 0 5px;
          cursor: pointer;
          color: #2e6fae;
          border: 1px solid #2e6fae;
          font-size: 150%;
          text-decoration: none;
          @include ease-transition(300ms);
          outline: 0;
          cursor: pointer;
          &:hover {
            color: #fff;
            background-color: #2e6fae;
          }
        }
      }
    }
  }
}

body.page-home {
  .footer-section {
    background-image: url('/assets/images/fondo-footer.jpg');
    @media (max-width: $btLG) {
      background-image: none;
      background-color: #021120;
    }
  }
}