formulario-consulta {
  .fixed-container {

    @include position(0);
    position: fixed;
    z-index: 10;
    visibility: visible;
    opacity: 1;
    
    &.mostrar-formulario {
      visibility: hidden;
      opacity: 0;
    }

    .formulario-consulta {
      position: absolute;
      @include position(0);
      @include ease-transition;
      background-color: #000d;
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;



      @media (max-width: $btMD) {
        min-height: 600px;
        // top:unset;
        // left:unset;
        // right: unset;
        // bottom: unset;
      }

      .boton-cerrar {
        @include ease-transition;
        cursor: pointer;
        align-self: flex-end;
        margin-right: 30%;
        font-size: 150%;

        @media (max-width: $btMD) {
          margin-right: 10%;
        }

        &:hover {
          color: #000;
        }

      }

      .titulo-formulario {
        font-family: titillium_bold;
        font-size: 180%;
        letter-spacing: 0.2rem;

        @media (max-width: $btMD) {
          font-size: 140%;
          text-align: center;
        }
      }

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;

        .campo-formulario {
          width: 50%;
          margin: 10px auto;

          @media (max-width: $btMD) {
            width: 100%;
          }

          .etiqueta {
            width: 100%;

            label {
              width: 100%;
              font-family: titillium_regular;
              letter-spacing: 0.2rem;
            }
          }

          .input-consulta {
            width: 100%;

            margin: auto;

            input,
            textarea {
              width: 100%;
              padding: 5px 15px;

              &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #333;
                font-family: titillium_light;
              }

              &::-moz-placeholder {
                /* Firefox 19+ */
                color: #333;
                font-family: titillium_light;
              }

              &:-ms-input-placeholder {
                /* IE 10+ */
                color: #333;
                font-family: titillium_light;
              }

              &:-moz-placeholder {
                /* Firefox 18- */
                color: #333;
                font-family: titillium_light;
              }
            }

          }
        }

        button {
          margin-top: 20px;
          font-family: titillium_bold;
          border: none;
          background: #005cb8;
          color: white;
          text-transform: uppercase;
          position: relative;

          height: 40px;
          width: 44%;
          right: 0;
          bottom: 0;
          cursor: pointer;
          @include ease-transition;
          &:disabled{
            opacity:0.5;
            cursor:not-allowed;
          }
          &:after {
            position: absolute;
            content: '';
            height: 0px;
            width: 0px;
            left: -30px;
            bottom: 0px;
            border-left: 30px solid transparent;
            border-top: 40px solid #005cb8;
          }

          &:hover {
            @include sombra(0 0 4px #ccc);

          }
        }
      }
    }
  }
}