productos-cabecera {
  .background {
    height: 850px;
    background-color: #ccc;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    @media (max-width: $btMD) {
      height: 700px;
    }
    img {
      object-fit: cover;
      width: 60%;
      @media (max-width: $btMD) {
        width: 100%;
      }
    }
    .presentacion-producto {
      display: grid;
      grid-template-columns: 50% 50%;
      width: 90%;
      @media (max-width: $btMD) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
      .trailer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &>span {
          text-align: right;
          color: #2c6fae;
          font-family: montserrat_regular;
          letter-spacing: 0.4rem;
          font-size: 200%;
          width: 90%;
          height: 90%;
        }
      }
      .producto-titulo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &>span {
          width: 90%;
          height: 90%;
          text-align: right;
          font-size: 380%;
          font-family: montserrat_black; // padding-right: 40px;
          line-height: 100%;
          color: #222;
          @media (max-width: $btMD) {
            font-size: 200%;
            overflow: unset;
          }
          @media (max-width: $btXL) {
            font-size: 300%;
          }
        }
      }
      .descripcion {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: #222 3px solid;
        &>span {
          width: 90%;
          height: 90%;
          color: #445;
          font-size: 160%;
          font-family: titillium_regular;
        }
        @media (max-width: $btMD) {
          border-top: #222 3px solid;
          padding-top: 40px;
          border-left: none;
          padding-left: 10px;
          width: 85%;
        }
        button.consulta {
          padding: 0;
          align-self: flex-start;
          margin-top: 20px;
          margin-left: 20px;
          cursor: pointer;
          font-family: montserrat_semi_bold;
          letter-spacing: 0.2rem;
          font-size: 70%;
          border: 1px solid #005cb8;
          width: 180px;
          height: 25px;
          text-align: center;
          @include sombra(0 0 4px #333);
          @include ease-transition;
          &:hover {
            background-color: #ddd;
            @include sombra(0 0 8px #333);
          }
        }
      }
    }
    .iconos {
      position: absolute;
      right: 3%;
      top: 30%;
      font-size: 200%;
      @media (max-width: $btMD) {
        right: 20px;
      }
      div {
        color: #ff0000;
        background-color: #ffffff;
        @include minmaxwh(60px);
        margin: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        @include sombra(0 3px 3px 0 #888);
        @include ease-transition;
        @media (max-width: $btMD) {
          @include minmaxwh(45px);
        }
        &:hover {
          background-color: #ff0000;
          color: #ffffff;
        }
      }
    }
  }
  .sintesis {
    background-image: url('/assets/images/fondo-sintesis-gris.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    @media (max-width: $btMD) {
      height: auto;
      background-image: none;
      background-color: #000;
      padding: 50px 0;
    }
    .titulo-sintesis {
      font-family: montserrat_semi_bold;
      font-size: 200%;
      letter-spacing: .8rem;
      position: relative;
      padding: 10px 25px;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 45px;
        height: 3px;
        background-color: #fff;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 45px;
        background-color: #fff;
      }
      ;
    }
    .contenido-sintesis {
      font-family: titillium_regular;
      font-size: 150%;
      width: 60%;
      line-height: 200%;
      @media (max-width: $btMD) {
        width: 80%;
      }
    }
  }
}