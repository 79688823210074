nosotros-timeline {
  background-color: #c8caca;
  height: 100vh;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: $btMD) {
    flex-direction: column;
    height: auto;
    padding-bottom: 120px;
  }
  .imagen-redonda {
    // margin: 60px 80px 0 122px;
    margin: 0;
    @media (max-width: $btMD) {
      margin: 100px 0 0 0;
    }
    img {
      @include minmaxwh(450px);
      object-fit: cover;
      border-radius: 50%;
      @media (max-width: $btMD) {
        @include minmaxwh(200px);
      }
      @media (max-width: $btLG) {
        @include minmaxwh(300px);
      }
    }
  }
  .timeline-perse {
    width: 40%; // margin: 10rem 0 12rem 0;
    position: relative;
    @media (max-width: $btMD) {
      width: 100%;
      overflow: unset;
    }
    .hitos-container {
      position: relative;
      @media (max-width: $btMD) {
        overflow: unset;
      }
      &:last-child {
        .hitos {
          .linea {
            display: none;
          }
        }
      }
      .hitos {
        position: relative;
        width: 50px;
        height: 5rem;
        @media (max-width: $btMD) {}
        .hito {
          @include minmaxwh(20px);
          border-radius: 50%;
          background-color: #005cb8;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          @include ease-transition;
          @media (max-width: $btMD) {}
          &.concentrico {
            @include minmaxwh(30px);
            background-color: #4f8dd0;
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              background-color: #005cb8;
              transform: translate(-50%, -50%);
              @include minmaxwh(20px);
              border-radius: 50%;
            }
          }
        }
        .linea {
          background-color: #005cb8;
          height: 85px;
          width: 2px;
          position: absolute;
          top: 13px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .texto {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        width: 400px;
        left: 50px;
        top: 0;
        @media (max-width: $btMD) {
          width: 80%;
          transform: translateX(10px);
        }
        @include ease-transition;
        .anio {
          &>span{
            letter-spacing: 0.2rem;
            font-family: montserrat_black;

          }
          span.titulo {
            font-family: montserrat_regular;
            text-transform: uppercase;
          }
        }
        .texto-hito {
          &>span{
            font-family: titillium_light;
            color: #484c4c;
          }
        }
        &.activo-hito {
          visibility: visible;
          opacity: 1; // background-color: #fff;
          // padding:10px;
          // border-radius:15px;
        }
        .linea-horizontal {
          width: 100%;
          height: 1px;
          background-color: #033363;
        }
      }
    }
  }
}