productos-caracteristicas {
  background-color: #ccc;
  padding-top: 70px;
  position: relative;
  .titulo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100px;
    &>span{
      padding: 4px 8px;
      position: relative;
      text-align: center;
      color: #005cb8;
      font-size: 200%;
      letter-spacing: 0.4rem;
      font-family: montserrat_semi_bold;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 3px;
        background-color: #005cb8;
        @media (max-width: $btMD) {
          width: 30px;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 40px;
        background-color: #005cb8;
        @media (max-width: $btMD) {
          height: 30px;
        }
      }
    }
    @media (max-width: $btMD) {
      width: 80%;
      font-size: 120%;
      padding-left: 8px;
      margin: 0 0 10px 4px;
    }
   
  }
  .caracteristicas {
    display: grid;
    grid-template-columns: 28% 40% 28%;
    grid-gap: 10px;
    margin: 0 auto;
    width: 90%;
    @media (max-width: $btMD) {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
    div {
      margin: 0 10px;
      text-transform: uppercase;
      @media (max-width: $btMD) {
        order: 1;
      }
      &.revestimiento,
      &.montaje,
      &.aislacion,
      &.alimentacionelectrica {
        text-align: right;
        @media (max-width: $btMD) {
          text-align: left;
          .guias {
            flex-direction: row-reverse;
            justify-content: flex-end;
          }
        }
      }
      .atributo {
        &>span{
          letter-spacing: 0.2rem;
          font-size: 75%;
          font-family: montserrat_bold;
        }
      }
      .dato {
        &>span{
          letter-spacing: 0.2rem;
          font-size: 75%;
          font-family: montserrat_regular;
        }
      }
      .guias {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .bolita {
          margin: 5px 0;
          @include minmaxwh(7px);
          background-color: #005cb8;
          border-radius: 50%;
        }
        .guia {
          margin: 0;
          width: 400px;
          height: 1px;
          background-color: #888;
        }
      }
    }
    .imagencentral {
      grid-row: span 4 / auto;
      @media (max-width: $btMD) {
        order: 0;
      }
    }
  }
}