home-nosotros {
  .contenedor-principal {
    background-color: #fff;
    @media (max-width: $btMD) {
      margin-bottom:100px;
    }
    .contenedor-importante {
      display: flex;
      justify-content: center;
      @media (max-width: $btMD) {
        flex-direction: column;
      }
      .info-texto {
        width: 35%;
        @media (max-width: $btMD) {
          width: 95%;
          margin-left: auto;
          margin-right: 10px;
        }
        .titulo {
          letter-spacing: 0.6rem;
          position: relative;
          padding: 10px 15px;
          &>span {
            font-family: montserrat_semi_bold;
            font-size: 200%;
          }
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 45px;
            height: 3px;
            background-color: #005cb8;
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 45px;
            background-color: #005cb8;
          }
        }
        .texto {
          width: 86%;
          margin: 25px 0;
          &>span {
            color: #333;
            line-height: 2.5rem;
            text-align: justify;
            font-size: 125%;
            font-family: titillium_regular;
          }
          @media (max-width: $btMD) {
            margin: 0 auto;
          }
        }
        .ver-mas {
          font-family: montserrat_semi_bold;
          letter-spacing: 0.2rem;
          padding: 5px 25px;
          border: none;
          background-color: #005cb8;
          color: #fff;
          font-size: 90%;
          cursor: pointer;
          @include ease-transition;
          @media (max-width: $btMD) {
            transform: translate(2%, 0);
            width: 95%;
          }
          &:hover {
            color: #005cb8;
            background-color: #fff;
            @include sombra(0 0 0 2px #005cb8);
          }
        }
      }
      .info-img {
        width: 40%;
        @media (max-width: $btMD) {
          width: 90%;
        }
      }
    }
    .contenedor-detalles {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 45px;
      @media (max-width: $btMD) {
        flex-direction: column;
      }
      .item-detalle {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        @media (max-width: $btMD) {
          width: 85%;
          margin-bottom: 10px;
        }
        img {
          @include minmaxwh(60px);
        }
        .titulo {
          margin-top: 7px;
          &>span {
            text-align: center;
            letter-spacing: 0.25rem;
            font-size: 95%;
            font-family: montserrat_extra_bold;
          }
        }
        .texto {
          width: 82%;
          margin-top: 15px;
          &>span {
            font-size: 115%;
            text-align: center;
            color: #444;
            font-family: titillium_light;
          }
        }
      }
    }
  }
}