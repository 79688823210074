productos-descripcion {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .descripcion {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        // margin: 0 auto;
        @media (max-width: $btMD) {
            flex-direction: column;
            width: auto;
            margin-top: 20px;
        }
    
        .imagen {
            margin: 0 20px;
            @media (max-width: $btMD) {
                margin:20px 0;
            }
        }
        .info {
            width: 60%;
            margin: 0 20px;
            @media (max-width: $btMD) {
                width: 90%;
            }
            .titulo {
                font-family: montserrat_semi_bold;
                letter-spacing: 0.4rem;
                font-size: 200%;
                position: relative;
                padding: 5px 13px;
                margin-bottom: 30px;
                @media (max-width: $btSM) {
                    font-size: 160%;
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 45px;
                    height: 3px;
                    background-color: #005cb8;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 3px;
                    height: 45px;
                    background-color: #005cb8;
                }
                ;
            }
            .texto {
                font-family: titillium_regular;
                line-height: 200%;
                font-size: 125%;
                color: #444;
                margin: 10px 0;
                text-transform: capitalize;
            }
            .control-botones {
                width: 100%;
                display: flex;
                justify-content: space-evenly;
                // margin: 0 -10px;
                // margin-top: 60px;
                @media (max-width: $btMD) {
                    flex-direction: column;
                    align-items: center;
                }
                button.ver {
                    width: 45%;
                    // margin: 0 10px;
                    padding: 2px 15px;
                    border: none;
                    background-color: #005cb8;
                    color: #fff;
                    font-family: montserrat_semi_bold;
                    letter-spacing: 0.1rem;
                    cursor: pointer;
                    font-size:70%;
                    height: 40px;
                    @include ease-transition;
                    @media (max-width: $btMD) {
                        margin-bottom:10px;
                        
                    }
                    @media (max-width: $btSM) {
                        width: 80%;
                    }
                    &:hover {
                        color: #005cb8;
                        background-color: #fff;
                        @include sombra(0 0 1px 0 #005cb8);
                    }
                }
                button.equip {
                    height: 40px;
                    width: 45%;
                    letter-spacing: 0.1rem;
                    // margin: 0 10px;
                    padding: 2px 15px;
                    border: none;
                    background-color: #fff;
                    color: #005cb8;
                    border: 1px solid #000;
                    font-family: montserrat_semi_bold;
                    font-size:70%;
                    cursor: pointer;
                    @include ease-transition;
                    @media (max-width: $btSM) {
                        width: 80%;
                    }
                    &:hover {
                        background-color: #005cb8;
                        color: #fff;
                        @include sombra(0 0 1px 0 #fff);
                    }
                }
            }
        }
    }
    .info-popup {
        position: fixed;
        @include position(0);
        z-index: 10;
        background: #000e;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        @include ease-transition;
        color: #eee;
        &.infopopup {
            opacity: 1;
            visibility: visible;
        }
        .cerrar-info-popup {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 60px;
            color: white;
            font-size: 120%;
            &:hover {
                color: #888;
            }
        }
        .info-interno {
            background-color: #eee;
            color: #222;
            width: 80%;
            padding: 20px;
            border: 4px solid #005cb8;
            .texto-popup {
                width: 70%;
                font-size: 120%;
                font-family: titillium_regular;
            }
            ul {
                margin-top: 20px;
            }
            .equipamiento {
                font-family: titillium_regular;
                font-size: 110%;
            }
        }
    }
    .planos-popup {
        position: fixed;
        @include position(0);
        z-index: 10;
        background: #000e;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        @include ease-transition;
        &.popup-planos {
            opacity: 1;
            visibility: visible;
        }
        img {
            width: 80%;
            height: 80%;
            object-fit: cover;
            @media (max-width: $btMD) {
                object-fit:contain;
            }
        }
        .close-planos {
            position: absolute;
            top: 20px;
            right: 60px;
            color: white;
            cursor: pointer;
            font-size: 120%;
            &:hover {
                color: #888;
            }
        }
    }
    galeria-imagenes {
        width: 100%;
        .medio-fondo {
            position: relative;
            left: 0;
            right: 0;
            height: 270px;
            background-color: #ccc;
            z-index: 0;
            @media (max-width: $btMD) {
                overflow: unset;
            }
            .tira-imagenes {
                margin: 170px auto 0 auto;
                width: 90%;
                height: 100%;
                position: relative;
                top: -50px;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media (max-width: $btMD) {
                    
                }
                .flechas {
                    z-index: 9;
                    cursor: pointer;
                    img {
                        height: 50px;
                        object-fit: cover;
                    }
                }
                .imagenes {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    transform: translate3d(0, 0, 0);
                    -webkit-transform: translate3d(0, 0, 0);
                    @include ease-transition(400ms);
                    position: relative;
                    overflow: hidden;
                    @media (max-width:$btMD){
                        // overflow-x: unset;
                    }
                    .contenedor-movil {
                        @include ease-transition(700ms);
                        @for $i from 1 through 15 {
                            &.activo-movil-#{$i} {
                                -webkit-transform: translateX($i * -100% );
                                transform: translateX($i * -100%);
                            }
                        }
                        position: relative;
                        width: 100%;
                        height: 100%;
                        .imagen {
                            position: absolute;
                            @for $i from 1 through 15 {
                                &.activo-#{$i} {
                                    -webkit-transform: translateX($i * 100%);
                                    transform: translateX($i * 100%);
                                }
                            }
                            left:0;
                            height: 100%;
                            width: 33%;
                            @media (max-width:$btMD){
                                width: 100%;
                            }
                            cursor: pointer;
                            @include ease-transition(300ms);
                            
                            img {
                                border: 4px solid #005cb8;
                                @include ease-transition(300ms);
                                margin: 0 20px;
                                position: absolute;
                                height: 100%;
                                width: 90%;
                                object-fit: cover;
                                background-color: #fff;
                                &:hover {
                                    @include sombra(0 13px 13px 7px #888);
                                     
                                }
                            }
                        }
                    }
                }
            }
        }
        .popup-imagen {
            z-index: 10;
            position: fixed;
            @include position(0);
            background-color: #000;
            opacity: 0;
            visibility: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            .popupflechas {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                @media (max-width: $btMD) {
                    position: absolute;
                }
                img {
                    width: 30%;
                    opacity: 0.8;
                    &:hover {
                        opacity: 1;
                    }
                    @media (max-width:$btMD) {
                        display: none;
                    }
                }
            }
            .popupimagenclose {
                position: absolute;
                top: 80px;
                right: 60px;
                color: white;
                cursor: pointer;
                font-size: 130%;
                @include minmaxwh(45px);
                border-radius:50%;
                background-color: #000;
                display:flex;
                justify-content: center;
                align-items: center;
                opacity:0.75;
                &:hover {
                    color: #888;
                }
            }
            &.popup {
                opacity: 1;
                visibility: visible;
            }
            img {
                width: 80%;
                height: 80%;
                object-fit: contain;
                &.coverimg {
                    object-fit: cover;
                }
            }
        }
    }
}