nosotros-cabecera {
  .background {
    height: 990px;
    background-image: url("/assets/images/portada4.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    @media (max-width: $btMD) {
      background-position: left;
      justify-content: flex-start;
      height: 500px;
      background-size: 1000%;
    }
    @media (max-width:$btLG) {
      background-position: left;
    }
    .titulo {
      width: 20%;
      margin-left: 7%;
      &>span {
        font-weight: normal;
        font-size: 260%;
        line-height: 130%;
        font-family: montserrat_medium;
      }
      span {
        &:last-child {
          font-weight: bold;
        }
      }
      
      @media (max-width: $btMD) {
        width: 90%;
        margin-top: 30%;
        font-size: 220%;
      }
      strong {
        font-family: montserrat_extra_bold;
      }
    }
    .texto {
      margin-top: 2%;
      width: 26%;
      margin-left: 7%;
      &>span{
        font-size: 135%;
        line-height: 210%;
        font-family: titillium_light;
      }
      @media (max-width: $btLG) {
        width: 50%;
        padding: 15px;
      }
      @media (max-width: $btMD) {
        width: 90%;
        font-size: 110%;
      }
    }
  }
}