home-cabecera {
  position: relative;
  .black {
    background-color: #2c6fae;
    .background {
      height: 985px;
      background-color: #000;
      background-image: url("/assets/images/portada3.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      color: #fff;
      @media (max-width: $btMD) {
        justify-content: flex-start;
        height: 700px;
      }
      .info {
        margin: 22% auto 6% auto;
        width: 75%;
        text-shadow: 3px 3px 6px #111;
        @media (max-width: $btMD) {
          margin: 40% auto 0 auto;
        }
        .descripcion {
          &>span {
            font-size: 250%;
            font-family: montserrat_medium;
            @media (max-width: $btMD) {
              font-size: 200%;
            }
          }
        }
        .trailer {
          display: flex;
          align-items: flex-end;
          &>span {
            font-size: 200%;
            font-family: montserrat_extra_bold;
            @media (max-width: $btMD) {
              font-size: 100%;
            }
          }
          .nombre{
            &>span{
              font-size: 230%;
            font-family: montserrat_extra_bold;
            @media (max-width: $btMD) {
              font-size: 100%;
            }
            }
          }
        }
        .call-to-action {
          font-family: montserrat_semi_bold;
          letter-spacing: 0.25rem;
          font-size: 95%;
          background: transparent;
          color: #fff;
          border: 1px solid #fff;
          text-shadow: 2px 2px 4px #111;
          @include sombra(2px 2px 4px #111);
          padding: 7px 40px;
          margin-top: 20px;
          cursor: pointer;
          @include ease-transition;
          &:hover {
            background: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
  .productos {
    width: 100%;
    display: flex;
    margin: 100px auto 0 auto;
    justify-content: center;
    position: relative;
    bottom: 300px;
    height: 0;
    @media (max-width: $btMD) {
      flex-direction: column;
      align-items: center;
      bottom: 200px;
      height: auto;
      margin: 0 auto;
    }
    .producto {
      cursor: pointer;
      border: 3px solid #2c6fae;
      width: 21vw;
      height: 140px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      color: #fff;
      @include ease-transition; 
      @media (max-width: $btXL) {
        margin: 0;
        width: 33vw;
      }
      @media (max-width: $btMD) {
        width: 90vw;
        margin: 10px 0;
      }
      &.hero-selecto {
        .black-screen {
          background: #0004;
        }
      }
      &:hover {
        .black-screen {
          background: #0009;
        }
      }
      .black-screen {
        background: #000c;
        position: absolute;
        @include position(0);
        @include ease-transition(300ms);
      }
      .info {
        z-index: 5;
        position: absolute;
        @include position(15%);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        letter-spacing: 0.3rem;
        font-size: 105%;
        text-shadow: 2px 2px 4px #111;
        .trailer {
          &>span {
            font-family: montserrat_regular;
          }
        }
        .nombre {
          &>span {
            font-family: montserrat_bold;
          }
        }
      }
      &:link,
      &:visited {
        text-decoration: none;
        color: #fff;
      }
      &:first-child {
        background-image: url('/assets/images/productos_destacados-1.jpg');
      }
      &:nth-child(2) {
        margin: 0 65px;
        background-image: url('/assets/images/productos_destacados-2.jpg');
        @media (max-width: $btMD) {
          margin: 10px 0;
        }
        @media (max-width: $btXL) {
          margin: 0;
        }
      }
      &:last-child {
        background-image: url('/assets/images/productos_destacados-3.jpg');
      }
    }
  }
}