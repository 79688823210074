@font-face {
  font-family: "montserrat_black"; 
  src: url("/assets/fonts/Montserrat-Black.otf");
}
@font-face {
  font-family: "montserrat_bold"; 
  src: url("/assets/fonts/Montserrat-Bold.otf");
}

@font-face {
  font-family: "montserrat_extra_bold"; 
  src: url("/assets/fonts/Montserrat-ExtraBold.otf");
}
@font-face {
  font-family: "montserrat_medium"; 
  src: url("/assets/fonts/Montserrat-Medium.otf");
}
@font-face {
  font-family: "montserrat_regular"; 
  src: url("/assets/fonts/Montserrat-Regular.otf");
}
@font-face {
  font-family: "montserrat_semi_bold"; 
  src: url("/assets/fonts/Montserrat-SemiBold.otf");
}
@font-face {
  font-family: "titillium_bold"; 
  src: url("/assets/fonts/TitilliumWeb-Bold_0.ttf");
}
@font-face {
  font-family: "titillium_light"; 
  src: url("/assets/fonts/TitilliumWeb-Light_1.ttf");
}
@font-face {
  font-family: "titillium_regular"; 
  src: url("/assets/fonts/TitilliumWeb-Regular_2.ttf");
}
