mobile-menu {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  @media(max-width: $btMD) {
    visibility: visible;
    width: 0;
    overflow: hidden;
    .logo-mobile{
      @include minmaxwh(45px);
      top: 20px;
      right: 20px;
      // transform: translateX(-50%);
      position: fixed;
      background-color: #000;
      @include sombra(0 3px 3px 0  #333);
      cursor: pointer;
      border-radius:50%;
      padding: 5px;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 70%;
        margin:auto;
      }
    }
    .menu-mobile {
      position: fixed;
      top: 20px;
      left: 20px;
      @include ease-transition;
      background-color: black;
      color: white;
      border-radius:50%;
      @include minmaxwh(45px);
      display: flex;
      justify-content: center;
      align-items: center;

      &.open {
        z-index: 999;
        @include sombra(0 3px 3px 0  #333);
      }

      &.home {
        top: 80px;
        z-index: 999;
        @include sombra(0 3px 3px 0  #333);
      }

      &.nosotros {
        top: 135px;
        z-index: 999;
        @include sombra(0 3px 3px 0  #333);
      }

      &.productos {
        top: 190px;
        z-index: 999;
        @include sombra(0 3px 3px 0  #333);
      }

      a {

        &:visited,
        &:link {
          color: white;
          text-decoration: none;
        }
      }
    }

    .productos-list {
      left: 20px;
      top:20px;
      z-index:3;
      position: fixed;
      @include ease-transition;
      overflow: hidden;
      &.menu-open{ 
        
      }
      &.productos-open{
        left:75px;
        display: flex;
        flex-direction:column;
        
      }
      .productos-items {
        border-radius: 5px;
        width: 45px;
        height: 45px;
        z-index: 999;
        position: absolute;
        top:0;
        left:0;
        overflow-y: unset;
        background-color: black;
        @include sombra(0 3px 3px 0  #333);
        font-family:montserrat_semi_bold;
        &.productos-open{
          position:relative;
          margin-bottom: 15px;
          height: 45px;
          width: 200px; 
          display: flex;
          justify-content: center;
          align-items: center;
          color:white;
        }
      }
     
    }
  }
}