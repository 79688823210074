@keyframes navbarMutation {
  0% {
    top: 180px;
    width: 100%;
  }

  50% {
    width: 50%;
  }

  100% {
    top: 70px;
  }
}

@keyframes elasticSides {
  0% {
    width: 80%;
  }

  100% {
    width: 95%;
  }
}

main-menu {
  width: 80%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3d(0,0,0,0);
  transform: translate(-50%, 0);
  background: transparent;
  @include ease-transition(300ms);

  @media (max-width: $btMD) {
    display:none;
  }

  &.enproductos {
    animation: elastiSides 350ms ease-in;
    width: 95%;
    @media (max-width:1281px){
      animation:unset;
    }
    .redes-sociales {
      ul {
        li {
          a {
            color: #2e6fae;
            border: 1px solid #2e6fae;
          }
        }
      }
    }

    .contactenos {
      opacity: 0;

    }

    #navbar {
      animation: navbarMutation 350ms ease-in;
      top: 70px;
      width: 50%;
      left: 50%;
      transform: translate(-50%, 0);
      background: rgba(255, 255, 255, 0.9);
      @media (max-width:1281px){
        left: 55%;
        top:150px; 
        width: 90%;
        animation: unset;
      }
      &>ul {
        li {

          a,
          .a {
            color: black;
            // text-shadow: 2px 2px 4px #111;

            &.active {
              span {
                &::after {
                  content: "";
                  background: #000;
                  // @include sombra(2px 2px 4px #111);
                  height: 1px;
                }
              }

              span {
                &::before {
                  content: "";
                  background: #000;
                  // @include sombra(2px 2px 4px #111);
                  height: 1px;
                }
              }
            }

            &:hover:not(.active) {
              span {
                &::after {
                  content: "";
                  background: #000;
                }
              }
            }
          }

          .dropdownProductos {
            @include ease-transition(450ms);
            position: absolute;
            top: 57px;
            left: 30%;
            width: 376px;
            background: rgba(255, 255, 255, 0.9);
            height: 0;
            overflow: hidden;

            &.dropdownActive {
              // padding: 10px 15px;
              height: 150px;
            }

            ul.dropdownUL {
              padding: 0;
            

              li.dropdownLI {
                margin:12px auto;
                a {
                  font-size: 70%;
                }

                &:hover {
                  a {

                    &::after {
                      content: "";
                      position: absolute;
                      left: 13px;
                      right: 0;
                      bottom: 0;
                      width: 70%;
                      height: 2px;
                      background: #000;
                      transform: translateX(-15px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  #brand-logo {
    position: absolute;
    top: 60px;
    left: 10px;
    cursor: pointer;

    @media (max-width: $btMD) {
      visibility: hidden;
      display:none;
    }
    .imglogo{
      background-repeat: no-repeat;
      width: 300px;
      height: 100px;
      background-image: url('/assets/images/marca-escalada-azul.png');
      &.logohome{
        background-image: url('/assets/images/marca-escalada-blanca.png');
      }
    }
    img {
      transform: translate3d(0,0,0,0);
      object-fit: cover;
      
    }
  }

  #navbar {
    position: absolute;
    left: 2%;
    top: 160px;
    width: 95%;
    background: rgba(4, 4, 16, 0.6);
    padding: 10px 15px;

    @media (max-width: $btMD) {
      visibility: hidden;
      display:none;
    }

    &>ul {
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      li {
        margin: 3px auto;
        list-style: none;
        @include ease-transition;

        &.productos {}

        .dropdownProductos {
          @include ease-transition(450ms);
          position: absolute;
          top: 60px;
          left: 44%;
          width: 376px;
          background: rgba(4, 4, 16, 0.6);
          height: 0;
          overflow: hidden;

          &.dropdownActive {
            // padding: 10px 15px;
            height: 154px;
          }

          ul.dropdownUL {
            padding: 0;
            margin:6px 20px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            li.dropdownLI {
              margin:12px 20px;
              a {
                font-size: 70%;
              }

              &:hover {
                a {

                  &::after {
                    content: "";
                    position: absolute;
                    left: 13px;
                    right: 0;
                    bottom: 0;
                    width: 72%;
                    height: 2px;
                    background: #fff;
                    transform: translateX(-15px);
                  }
                }
              }
            }
          }
        }

        a,
        .a {
          display: block;
          padding: 3px;
          cursor: pointer;
          color: #fff;
          text-decoration: none;
          @include ease-transition(300ms);
          outline: 0;
          text-transform: uppercase;
          letter-spacing: 0.3rem;
          font-family: montserrat_regular;
          position: relative;

          span {
            &::after {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              width: 0;
              height: 0;
              @include ease-transition(300ms);
            }
          }

          span {
            &::before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              width: 0;
              height: 0;
              @include ease-transition(300ms);
            }
          }

          &.active {
            position: relative;

            span {
              &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 90%;
                height: 2px;
                background: #fff;
                transform: translateX(-15px);
              }
            }

            span {
              &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                width: 90%;
                height: 2px;
                background: #fff;
                transform: translateX(15px);
              }
            }
          }

          &:hover:not(.active) {
            position: relative;

            span {
              &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 95%;
                height: 2px;
                background: #fff;
              }
            }
          }
        }
      }
    }
  }

  .contactenos {
    position: absolute;
    top: 68px;
    left: 53%;
    transform: translate(-50%, 0);
    opacity: 1;
    @include ease-transition(300ms);
    cursor: pointer;

    @media (max-width: $btMD) {
      visibility: hidden;
    }

    button {
      all: unset;
      font-family: montserrat_semi_bold;
      color: #2e6fae;
      padding: 10px 22px;
      background-color: rgba(10, 16, 34, 0.8);
      text-transform: uppercase;
      letter-spacing: 0.1rem;
      @include ease-transition(300ms);

      &:hover {
        background-color: rgba(10, 16, 98, 0.8);
        color: #26f;

      }
    }
  }

  .redes-sociales {
    position: absolute;
    right: 30px;
    top: 64px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;

    @media (max-width: $btMD) {
      visibility: hidden;
    }
    @media (max-width:$btXL){
      right: 20px;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width:$btXL){
        // flex-wrap: wrap;
        width: 200px;
        height: 120px;
      }
      li {
        list-style: none;

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 45px;
          max-width: 45px;
          min-height: 45px;
          max-height: 45px;
          margin: 0 5px;
          cursor: pointer;
          color: #000;
          border: 1px solid #000;
          background-color: #fff;
          font-size: 150%;
          text-decoration: none;
          @include ease-transition(300ms);
          outline: 0;

          &:hover {
            background-color: #666;
            color: #fff;
          }
        }
      }
    }
  }
}